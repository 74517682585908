<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="text-center w-100">
          <div class="title">Undangan {{ title }}</div>
          <span>from</span>
          <div class="mb-5 name">
            {{ wedding.man.surname }} 
            <span>&</span> 
            {{ wedding.woman.surname }}
          </div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="bg-white agw-content"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <AGWIntro id="intro" />

      <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" />
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />
      <AGWMap
        :height="450"
        :url="map"
        :directionUrl="direction"
        :location="location"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <FooterCeremonyku ref="footer" />

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/ceremonyku/tedi-trisna/tedi_bali_5-.jpg?updatedAt=1630337502193&tr=w-800";
const ImageHero2 =
  "https://ik.imagekit.io/ceremonyku/tedi-trisna/tedi_5-.jpg?updatedAt=1630337501043&tr=w-800";
const ImageHero3 =
  "https://ik.imagekit.io/ceremonyku/tedi-trisna/PicsArt_08-11-01.15.50.jpg?updatedAt=1630337500733&tr=w-800";

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero/HeroTwo";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";
import Himbauan from "./components/Himbauan.vue";
import FooterCeremonyku from "@/components/Footer/Ceremonyku";

import { setInterval } from "timers";

// const SESSION = [["2021-06-03 11:00", "Selesai"]];

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
    FooterCeremonyku,
  },
  data() {
    return {
      title: "Pawiwahan",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location: "Br. Dinas Muntig (Batuniti), Desa Tulamben, Kec. Kubu, Kab. Karangasem, Bali",
      map:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3948.0116182446395!2d115.61100671478147!3d-8.301641594027448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd20118f8c29051%3A0x85fc55b06de848e5!2sHome%20Tomblos!5e0!3m2!1sid!2sid!4v1630337896447!5m2!1sid!2sid",
      direction:
        "https://www.google.com/maps/dir//Home+Tomblos,+Jl.+Kubu,+Tulamben,+Kubu,+Kabupaten+Karangasem,+Bali+80852/@-8.3016416,115.6110067,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2dd20118f8c29051:0x85fc55b06de848e5!2m2!1d115.6131954!2d-8.3016416?hl=id-ID",
      time: {
        start: this.$moment("2021-09-17 10:00"),
        end: "Selesai",
      },
      wedding: {
        man: {
          name: "I Putu Tedi Wirawan",
          surname: "Tedi",
          parents: " I Made Astawa & Ni Luh Sudi",
          description: "Putra pertama",
          address: "Br. Dinas Muntig, Desa Tulamben, Kec. Kubu, Kab. Karangasem, Bali",
          photo:
            "https://ik.imagekit.io/ceremonyku/tedi-trisna/1.jpeg?updatedAt=1630337523275&tr=w-777,h-972,fo-custom,cm-extract",
        },
        woman: {
          name: "Ni Kadek Trisna Widiastini",
          surname: "Trisna",
          parents: "I Nengah Dana & Ni Luh Suastini",
          address: "Br. Dinas Bantas, Desa Baturinggit, Kec. Kubu, Kab.Karangasem, Bali",
          description: "Putri kedua",
          photo:
            "https://ik.imagekit.io/ceremonyku/tedi-trisna/2.jpeg?updatedAt=1630337523515&tr=w-858,h-1074,fo-custom,cm-extract",
        },
      },
      mepandes: [
      ],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true,
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false,
      },
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
  },
  mounted() {
    // const url = new URLSearchParams(window.location.search);
    // const [start, end] = SESSION[(Number(url.get("sesi")) || 1) - 1] || SESSION[0];

    // this.time.start = this.$moment(start);
    // this.time.end = end;

    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-fade-enter-active {
  transition: all 1s ease;
}
.overflow-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.overflow-fade-enter,
.overflow-fade-leave-to {
  opacity: 0;
}
</style>
